<template>
  <div>
    <!-- 头部 -->
    <div class="cssHead">
      <!-- 内容 -->
      <div class="content">
        <!-- <div class="txt">24小时自助购买</div> -->
        <div class="txt" >
          <img class="simg" src="../logo/sp2.png" alt="">
          <span class="ziding">{{ zitxt }}</span>
        </div>
        <div class="kefu">
          <div v-for="item in arrkefu" :key="item.id">
            <div @touchstart="subtouchStart($event,item.value)"  v-if="item.ok && item.title == '在线客服'" class="item" @click="tiao(item.value)">
            <!-- <div v-if="item.ok && item.title == '在线客服'" class="item" @click="tiao(item.value)"> -->
              <div>{{ item.title }}</div>
              <!-- <div class="zhes" ></div> -->
            </div>
            <div  @touchstart="subtouchStart($event,item.value)" v-if="item.ok && item.title == 'QQ客服'" class="item" @click="tiao(item.value)">
            <!-- <div v-if="item.ok && item.title == 'QQ客服'" class="item"  @click="tiao(item.value)"> -->
              <div>{{ item.title }}</div>
              <!-- <div class="zhes"></div> -->

            </div>
            <div @touchstart="subtouchStart($event,item.value)"  v-if="item.ok && item.title == '电报客服'" class="item" @click="tiao(item.value)">
            <!-- <div v-if="item.ok && item.title == '电报客服'" class="item" @click="tiao(item.value)"> -->
              <div>{{ item.title }}</div>
              <!-- <div class="zhes"></div> -->
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- 主要内容 -->
    <div class="cssMiddle">
      <!-- 公告 -->
      <!-- <div class="notice" v-if="showmotice">
        <div class="left">
          <div class="icon">公告</div>
          <div class="txt">
            <Notice v-if="guangbo.length > 0" :noticeData="guangbo" />
          </div>
        </div>
        <div class="right" @click="showmotice = false">X</div>
      </div> -->
      <!-- 主题 -->
      <div class="theme">
        <!-- 列表 -->
        <!-- 多个显示 -->
        <div class="shop" v-if="arrlist.length > 1">
          <div v-for="item in arrlist" :key="item.id" class="shops" :class="[selectshop.id == item.id ? 'okshops' : '']"
            @click="funlist(item)">
            <div class="top">
              <img src="../logo/zuanshi1.png" alt="" />
              <span>{{ item.name }}</span>
            </div>
            <!-- <span class="tishis">{{ objbianji.one }}</span> -->
            <div class="bottom">
              <img src="../logo/vip_logo.png" alt="" />
              <div class="txt">
                <span class="qian">￥</span>
                <span class="jiage">{{ item.price }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 单个显示 -->
        <div v-else-if="arrlist.length == 1" class="shopone">
          <div class="top">
            <img src="../logo/zuanshi1.png" alt="" />
            <span>{{ selectshop.name }}</span>
          </div>
          <!-- <span class="tishis">{{ objbianji.one }}</span> -->

          <div class="bottom">
            <img src="../logo/vip_logo.png" alt="" />
            <div class="txt">
              <span class="qian">￥</span>
              <span class="jiage">{{ selectshop.price }}</span>
            </div>
          </div>
        </div>
        <!-- 未加载的 -->
        <div v-else class="jiazaizong">加载中...</div>


        <!-- 提示 -->
        <div class="csstips">
          <!-- <div class="head">
            <img src="../logo/tishi1.png" alt="">
            <span>公告</span>
          </div> -->
          <div class="cont" v-html="sbjtips"></div>
        </div>


        <!-- 支付方式 -->
        <div class="pay">
          <div class="left">
            <div class="icon">支付方式</div>
          </div>
          <div class="right" v-if="arrpay.length != 0">
            <div class="paybox">
              <div class="btn">
                <div v-for="item in arrpay" :key="item.id" class="paybtn"
                  :class="[selectpay.id == item.id ? 'paybtnok' : '']" @click="funxuanpay(item)">
                  <img :src="item.image" alt="" />
                  <span>{{ item.name }}</span>
                  <img v-if="item.id == selectpay.id" class="okzhong" src="../logo/xuanzhongyouxiaright.png" alt="" />
                </div>

              </div>
            </div>
            <!-- <div class="prompt">海外观众可以加入YouTube频道会员，截图联系客服，帮您开通。</div> -->
          </div>

          <div v-else class="jiazaizongs">加载中...</div>
        </div>
        <div class="prompt">{{ objbianji.san }}</div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="cssBottom">
      <!-- 内容 -->
      <div class="content">
        <div class="left" @click="funchabtn()">
          <!-- <img class="oneimg" src="../logo/gouwuche.png" alt="">
          <span class="onespan">永久卡(永久有效)</span> -->
          <img class="twoimg" src="../logo/dianbao.png" alt="" />
          <span class="twospan">付款后进群</span>
        </div>
        <div class="right">
          <div class="jine">
            <span class="one">支付金额：</span>
            <span class="two">￥</span>
            <span class="san">{{ selectshop.price }}</span>
            <span class="si">￥{{ selectshop.price }}</span>
          </div>
          <!-- <div class="btn" @click="funpay()"><span class="one">立即支付</span><span class="two">￥{{ selectshop.price }}</span> -->
          <div class="btn" @click="funpay()">
            <span class="one">立即支付</span>
          </div>
        </div>
      </div>
    </div>





    <!-- 客服 -->
    <!-- <div class="csskefu">
      <div v-for="item in arrkefu" :key="item.id">
        <div v-if="item.ok && item.title == '在线客服'" class="item zaixian" @click="tiao(item.value)">
          {{ item.title }}
        </div>
        <div v-if="item.ok && item.title == 'QQ客服'" class="item qq" @click="tiao(item.value)">
          {{ item.title }}
        </div>
        <div v-if="item.ok && item.title == '电报客服'" class="item dianbao" @click="tiao(item.value)">
          {{ item.title }}
        </div>
      </div>
    </div> -->

    <!-- 遮罩层 -->
    <div class="zhe" v-if="showzhe">
      <img src="../logo/jiazai.png" alt="" />
    </div>

    <!-- 提示框 -->
    <!-- <div class="tishi" v-show="showtishi&&tishi!=''" :style="{top:tops+'rem'}">{{ tishi }}</div> -->
    <div class="tishi" :class="[showtishi ? 'tishis' : '']" v-html="tishi"></div>
  </div>
</template>
<script>





//import Notice from "../components/Notice";
// import ClipboardJS from "clipboard";

/* 
  apilist 商品列表
  pay     支付方式
  setData  公共配置
  create  下单
  paycreate  支付
  detail  查询订单
*/
import {
  apilist,
  apipay,
  setData,
  create,
  paycreate,
  detail,
  generate,
} from "../units/api.js";
export default {
  data() {
    return {
      arrlist: [], //商品列表
      selectshop: "", //选中的商品
      arrpay: [], //支付方式
      selectpay: "", //选中的支付方式
      sbjpass: "", // 查询密码
      showmotice: true, //公告显示隐藏
      arrkefu: [], //客服信息
      guangbo: [], //广播信息
      showtishi: false, //提示框判断

      tishi: "", //提示框信息

      lastTime: 0, //控制节流的时间
      showzhe: false, //遮罩层

      objbianji: {
        //编辑的信息
        one: "", //商品
        two: "", //查询密码
        san: "", //支付方式
      },

      zitxt: "", //自定义表头


      sbjtips: '', //提示信息
    };
  },

  components: {
    // Notice,
  },

  created() {
  
    
    if (
      this.$route.query.shop_id == "" ||
      this.$route.query.shop_id == null ||
      this.$route.query.shop_id == undefined
    ) {
      this.funapilist();
    } else {
      this.funapilist(this.$route.query.shop_id);
    }
    this.funapipay();
    this.funsetData();
    this.fungenerate();
  },

  methods: {

    //公共配置
    funsetData() {
      setData().then((res) => {
        let a = null; //存放客服列表
        let b = null; //存放客服显示

        res.data.forEach((e) => {
          if (  //获取客服的数据
            e.title == "在线客服" ||
            e.title == "QQ客服" ||
            e.title == "电报客服"
          ) {
            e.ok = false;
            this.arrkefu.push(e);
          }



          if (e.title == "广播") {
            this.guangbo.push(e.value);
          }
          if (e.title == "选择要显示的客服") {
            a = JSON.parse(e.content);
            b = e.value.split(",");
          }
          if (e.title == "编辑语1") {
            this.objbianji.one = e.value;
          }
          if (e.title == "编辑语2") {
            this.objbianji.two = e.value;
          }
          if (e.title == "编辑语3") {
            this.objbianji.san = e.value;
          }
          if (e.title == "编辑4") {
            this.sbjtips = e.value;
          }
          if (e.title == "自定义额头") {
            this.zitxt = e.value;
          }
          
        });

        //根据数据判断客服显示隐藏
        b.forEach((e) => {
          this.arrkefu.forEach((i) => {
            if (i.title == a[e]) {
              i.ok = true;
            }
          });
        });
        // this.arrkefu.push({title:'QQ客服',ok:true});


        // console.log(this.arrkefu);

      });
    },
    // 获取商品列表
    funapilist(a) {
      
      apilist({ page: 1, pageSize: 999, shop_id: a }).then((res) => {
        if (res.data.current_page == 1) {
          this.arrlist = res.data.data;
          this.selectshop = res.data.data[0];
        } else {
          // alert("商品列表加载失败，请联系管理员")
        }
      });
    },
    //获取支付方式
    funapipay() {
      apipay().then((res) => {
        this.arrpay = res.data;
        this.selectpay = res.data[0];
      });
    },


    // 点击商品
    funlist(e) {
      this.selectshop = e;
    },
    // 点击选择支付方式
    funxuanpay(e) {
      this.selectpay = e;
    },


    //定时显示的提示框
    funti(e) {
      this.tishi = e;
      this.showtishi = true;
      let a = setInterval(() => {
        this.showtishi = false;
        this.tishi = "";
        clearInterval(a);
      }, 2000);
    },
    //开始非定时显示的提示框
    funtifei(e) {
      this.tishi = e;
      this.showtishi = true;
    },
    funtifeion() {
      this.showtishi = false;
      this.tishi = "";
    },

    // 进群查询
    funchabtn() {
      let a = localStorage.getItem("order_num");
      if (new Date().getTime() - this.lastTime > 1000) {
        // this.funtifei("正在下单，请勿重复点击")
        this.showzhe = true;
        detail({ order_num: a })
          .then((res) => {
            // console.log(res);
            this.showzhe = false;
            if (res.code == 1) {
              this.$router.push({
                path: "/order?id=" + a,
              });

            } else {
              this.funti("未检测到支付信息<br/>请完成支付后点击进群");
            }
          })
          .catch(() => {
            this.funti("查询超时");

            this.showzhe = false;
          });
      } else {
        // console.log("点击了两次");

      }
    },


    //生成随机数
    fungenerate(a) {
      generate().then((res) => {
        if (res.code == 1) {
          this.sbjpass = res.data;
          if (a == 1) {
            this.funzhipay()
          }
        }
      }).catch(() => {

      });
    },

    funzhipay() {
      this.showzhe = true;
      create({
        shop_id: this.selectshop.id,
        pay_id: this.selectpay.id,
        search_password: this.sbjpass,
      }).then((res) => {
        if (res.code == 1) {
          localStorage.setItem('order_num', res.data.order_num)
          //支付的事件
          paycreate({
            order_num: res.data.order_num,
          }).then((ress) => {
            this.showzhe = false;
            if (ress.code == 1) {
              this.lastTime = 0
              window.location.href = ress.data.result_url;
            }
          }).catch(() => {
            this.showzhe = false;
            this.funti("拉起支付失败,请稍后尝试或切换支付方式");
          });

          //code == 0 的时候 支付密码重复
        } else if (res.code == 0) {
          this.sbjpass = "";
          this.fungenerate(1);
        }
      }).catch(() => {
        this.showzhe = false;
        this.funti("下单失败,请联系客服");
      });
    },



    //点击支付
    funpay() {
      if (new Date().getTime() - this.lastTime > 1000) { //防止多次点击
        this.lastTime = new Date().getTime()

        this.funzhipay()
      }
    },

    // 点击查询
    funquery() {
      this.$router.push({
        path: "/order",
      });

    },

    //客服跳转
    tiao(e) {
      window.location.href = e
    },



    subtouchStart(event,e){
      window.location.href = e

      event.preventDefault();
    },
  },
};
</script>
<style scoped lang="scss">
.router-link {
  text-decoration: none !important;
}




@media screen and (min-width: 1200px) {
  .jiazaizong {
    width: 100%;
    height: 120px;
    line-height: 120px;
    color: #727272;
    font-size: 40px;
    margin-bottom: 24px;
    font-weight: bold;
    margin-left: 30px;
  }

  .jiazaizongs {
    text-align: center;
    color: #727272;
    font-size: 40px;
    font-weight: bold;
  }

  //头部
  .cssHead {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 72px;
    opacity: 1;
    background: #3369ff;
    z-index: 8;

    //头部内容
    .content {
      margin: 0 auto;
      width: 970px;
      height: 100%;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      // 左侧文案
      .txt {
        font-family: Source Han Sans;
        font-size: 20px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0em;
        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #ffffff;
        // line-height: 72px;
        display: flex;
        align-items: center;
        .simg{
          width: 30px;
        }
        .ziding{
          margin-left: 8px;
          // font-size:;
          width: 640px;
        }
      }

      // 右侧按钮
      .kefu {
        font-size: 14px;
        display: flex;

        .item {
          padding: 5px 15px;
          cursor: pointer;
          color: #ffffff;
          border-radius: 60px;
          border: 1px solid #ffffff;
          margin-right: 10px;
          position: relative;
          overflow: hidden;
          .zhes{
            color: rgba(0, 0, 0, 0);
            position: absolute;
            top: 0;
            left: 0;
            // padding: 5px 15px;
            width: 100%;
            height:100%;
          }
        }
      }
    }
  }

  // 主要内容
  .cssMiddle {
    margin: 72px auto 92px;
    padding: 10px 0;
    width: 980px;

    // 公告
    .notice {
      width: 100%;
      height: 44px;
      border-radius: 4px;
      background: #fff2ed;
      box-shadow: 0px 2px 7px 0px rgba(126, 39, 0, 0.08);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 26px 0 16px;
      font-size: 16px;
      box-sizing: border-box;
      margin-bottom: 10px;

      // 左侧
      .left {
        display: flex;

        .icon {
          width: 48px;
          height: 26px;
          border-radius: 4px;
          background: #ff8955;
          font-family: Source Han Sans;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0em;
          font-variation-settings: "opsz" auto;
          font-feature-settings: "kern" on;
          color: #ffffff;
          text-align: center;
          line-height: 26px;
          margin-right: 12px;
        }

        .txt {
          width: 730px;
          height: 26px !important;
          margin-top: 2px;
          font-family: Source Han Sans;
          font-size: 16px;
          color: #303030;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: bold;
        }
      }

      // 右侧
      .right {
        color: #ff8955;
        cursor: pointer;
      }
    }

    // 主题
    .theme {
      width: 100%;
      padding: 50px;
      border-radius: 8px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
      box-sizing: border-box;

      font-size: 16px;

      // 商品
      .shop {
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        width: 880px;

        .shops {
          // min-width: 260px; 未修改pc
          min-width: 210px;
          width: fit-content;
          max-width: 880px;
          margin-right: 10px;

          background: linear-gradient(284deg, #fee5b6 0%, #fff4da 52%);
          //height: 120px;
          margin-bottom: 10px;
          border-radius: 8px;
          padding: 15px;
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          flex-direction: column;
          justify-content: space-between;
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;

          .top {
            display: flex;
            align-items: flex-start;

            img {
              width: 38px;
              height: 25px;
              // width: 36px; 未修改pc
              // height: 28px;
            }

            span {
              font-family: Source Han Sans;
              font-size: 18px;
              font-weight: bold;
              line-height: normal;
              text-align: justify;
              letter-spacing: 0em;
              font-variation-settings: "opsz" auto;
              font-feature-settings: "kern" on;
              color: #5a3312;
              width: 100%;
            }
          }

          .tishis {
            font-size: 14px;
            width: 220px;
            color: #906a40;
            white-space: normal;
          }

          .bottom {
            margin-top: 13px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            img {
              width: 45px;
              height: 18px;
              // width: 69px;未修改pc
              // height: 28px;
            }

            .txt {
              // position: absolute;
              // right: 16px;
              // bottom: 8px;
              color: #f7761a;

              .qian {
                font-family: HarmonyOS Sans SC;
                font-weight: 700;
                // font-size: 20px;未修改pc
                font-size: 14px;
                font-variation-settings: "opsz" auto;
              }

              .jiage {
                font-family: HarmonyOS Sans SC;
                font-weight: 700;
                // font-size: 32px; 未修改pc
                font-size: 21px;
                font-variation-settings: "opsz" auto;
              }
            }
          }
        }

        .okshops {
          box-shadow: 0px 0px 1px 2px #e7921b;
        }
      }

      .shopone {
        background: linear-gradient(284deg, #fee5b6 0%, #fff4da 52%);
        // height: 200px;

        margin-bottom: 10px;
        border-radius: 8px;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        flex-direction: column;
        justify-content: space-between;
        cursor: default;
        overflow: hidden;
        white-space: nowrap;

        .top {
          display: flex;
          align-items: flex-start;

          img {
            width: 70px;
            height: 50px;
          }

          span {
            font-family: Source Han Sans;
            font-size: 32px;
            font-weight: bold;
            line-height: normal;
            text-align: justify;
            letter-spacing: 0em;
            font-variation-settings: "opsz" auto;
            font-feature-settings: "kern" on;
            color: #5a3312;
            width: 100%;
          }
        }

        .bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 25px;

          img {
            width: 175px;
            height: 72px;
            margin-left: 20px;
          }

          .txt {
            color: #f7761a;
            margin-right: 15px;

            .qian {
              font-family: HarmonyOS Sans SC;
              font-weight: 700;
              font-size: 32px;
              font-variation-settings: "opsz" auto;
            }

            .jiage {
              font-family: HarmonyOS Sans SC;
              font-weight: 700;
              font-size: 56px;
              font-variation-settings: "opsz" auto;
            }
          }
        }

        .tishis {
          font-size: 16px;
          // width: 220px;
          color: #906a40;
          white-space: normal;
        }
      }

      // 提示
      .csstips {
        padding:10px 20px;
        background-color: #f7f7f7;
        border-radius: 10px;
        cursor: default;

        .head {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }

          span {
            font-size: 20px;
            font-weight: bold;
            color: #000000;
          }
        }


      }

      // 支付方式
      .pay {
        //display: flex;
        padding-top: 20px;
        //align-items: center;

        .left {
          font-size: 18px;
          font-family: Source Han Sans;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0em;
          font-variation-settings: "opsz" auto;
          font-feature-settings: "kern" on;
          color: #000000;
          width: 108px;
          height: 40px;
          line-height: 40px;
        }

        .right {
          // max-width: 670px;
          margin-top: 10px;
          margin-bottom: 10px;

          .paybox {
            .btn {
              // width: 480px;

              display: flex;
              flex-wrap: wrap;

              .paybtn {
                cursor: pointer;
                width: 220px;
                height: 64px;
                border-radius: 4px;
                opacity: 1;
                background: #f7f7f7;
                margin-bottom: 16px;
                margin-right: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                img {
                  width: 38px;
                  height: 38px;
                  margin-right: 10px;
                }

                span {
                  font-family: Source Han Sans;
                  font-size: 15px;
                  font-weight: bold;
                  line-height: normal;
                  letter-spacing: 0em;
                  font-variation-settings: "opsz" auto;
                  font-feature-settings: "kern" on;
                  color: #191919;
                }

                .okzhong {
                  position: absolute;
                  right: -1px;
                  bottom: -1px;
                  width: 24px;
                  height: 24px;
                  margin-right: 0;
                }
              }

              .paybtnok {
                box-shadow: 0px 0px 0px 2px #3367ff;
              }
            }
          }
        }
      }

      .prompt {
        font-size: 15px;
        font-weight: normal;
        line-height: 20px;
        text-align: justify;
        letter-spacing: 0em;
        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #878787;
        // margin: 16px 0 0 110px;
        // margin-left: 104px;
      }
    }
  }

  //底部
  .cssBottom {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 72px;
    opacity: 1;
    background: #ffffff;
    z-index: 9;
    box-shadow: inset 0px 1px 0px 0px #f3f3f3;

    //底部内容
    .content {
      margin: 0 auto;
      padding: 0 15px;
      box-sizing: border-box;
      width: 980px;
      height: 100%;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        padding: 10px 35px;
        background-color: #e3edff;
        border-radius: 100px;
        cursor: pointer;

        .oneimg {
          width: 32px;
          height: 32px;
        }

        .onespan {
          margin-left: 13px;
          font-size: 16px;
          font-weight: bold;
          line-height: 22px;
          text-align: justify;
          letter-spacing: 0em;
          font-variation-settings: "opsz" auto;
          font-feature-settings: "kern" on;
        }

        .twoimg {
          width: 28px;
          margin-right: 8px;
        }

        .twospan {
          font-size: 16px;
          color: #2a77e9;
          font-weight: bold;
        }
      }

      .right {
        display: flex;

        .jine {
          margin-top: 5px;

          .one {
            font-family: Source Han Sans;
            font-size: 16px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: 0em;
            font-variation-settings: "opsz" auto;
            font-feature-settings: "kern" on;
            color: #444;
          }

          .two {
            font-family: Source Han Sans;
            font-weight: 700;
            font-size: 16px;
            font-variation-settings: "opsz" auto;
            color: #3367ff;
          }

          .san {
            font-family: Source Han Sans;
            font-weight: 700;
            font-size: 28px;
            font-variation-settings: "opsz" auto;
            color: #3367ff;
          }

          .si {
            text-decoration: line-through;
            font-family: Source Han Sans;
            font-weight: 400;
            font-size: 14px;
            font-variation-settings: "opsz" auto;
            color: #c5c5c5;
            margin-left: 8px;
          }
        }

        .btn {
          margin-left: 30px;
          width: 120px;
          height: 48px;
          border-radius: 620px;
          background: #3369ff;
          line-height: 48px;
          text-align: center;
          color: #fff;
          cursor: pointer;

          .two {
            opacity: 0;
            position: absolute;
          }
        }
      }
    }
  }



  // 客服
  .csskefu {
    position: fixed;
    right: 0;
    top: 145px;
    font-family: Source Han Sans;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
    font-variation-settings: "opsz" auto;
    font-feature-settings: "kern" on;
    color: #ffffff;

    .item {
      width: 100px;
      height: 40px;
      opacity: 1;
      box-shadow: 0px 2px 4px 0px rgba(132, 87, 249, 0.27);
      border-radius: 8px 0 0 8px;
      margin-bottom: 10px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }

    .zaixian {
      background: #f53d4f;
    }

    .qq {
      background: #3369ff;
    }

    .dianbao {
      background: linear-gradient(270deg, #3d68f5 0%, #e940ff 100%);
    }
  }

  // 提示
  .tishi {
    cursor: default;
    position: fixed;
    left: 50%;
    top: -10vh;
    transform: translate(-50%, -50%);
    font-family: Source Han Sans;
    font-size: 20px;
    z-index: 999;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px 40px;
    // min-height: 54px;
    // line-height: 54px;
    border-radius: 3rem;
    transition: all 0.2s ease-in-out;
    text-align: center;
  }

  .tishis {
    top: 50vh;
  }

  //遮罩层
  .zhe {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    text-align: center;

    img {
      position: absolute;
      width: 100px;
      top: 50vh;
      left: 50vw;
      margin-top: -50px;
      margin-left: -50px;
      transform-origin: 50px 50px;
      animation: slidein 2s linear infinite;
    }

    @keyframes slidein {
      0% {
        transform: rotate(0deg);
      }

      50% {
        transform: rotate(180deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .jiazaizong {
    width: 100%;
    height: 120rem;
    line-height: 120rem;
    color: #727272;
    font-size: 40rem;
    margin-bottom: 24rem;
    font-weight: bold;
    margin-left: 30rem;
  }

  .jiazaizongs {
    text-align: center;
    color: #727272;
    font-size: 40rem;
    font-weight: bold;
  }

  div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  }

  // 头部
  .cssHead {
    position: fixed;
    left: 0rem;
    top: 0rem;
    width: 375rem;
    height: 52rem;
    opacity: 1;
    background: linear-gradient(to right, #3582fe, #439ff9);
    z-index: 8;
    box-sizing: border-box;

    //头部内容
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      padding: 0 20rem;

      // 左侧文案
      .txt {
        font-size: 16rem;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0em;
        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #ffffff;
        display: flex;
        align-items: center;
        .simg{
          width: 24rem;

        }
        .ziding{
          margin-left: 5rem;
          font-size: 16rem;
          max-width: 150rem;
        }
        
      }

      // 右侧按钮
      .kefu {
        font-size: 12rem;
        display: flex;

        .item {
          padding: 5rem 7rem;
          cursor: pointer;
          color: #ffffff;
          border-radius: 60rem;
          border: 1rem solid #ffffff;
          margin-left: 5rem;
          position: relative;
          overflow: hidden;
          .zhes{
            color: rgba(0, 0, 0, 0);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height:100%;
          }
        }
      }

    }
  }

  //内容
  .cssMiddle {
    padding: 68rem 14rem 97rem;
    box-sizing: border-box;
    background-color: #ffffff;
    width: 375rem;

    // 公告
    .notice {
      width: 100%;
      height: 44rem;
      border-radius: 4rem;
      background: #fff2ed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16rem 0 8rem;
      box-sizing: border-box;
      margin-bottom: 12rem;

      // 左侧
      .left {
        display: flex;

        .icon {
          width: 43rem;
          padding: 0rem 8rem;
          box-sizing: border-box;
          height: 25rem;
          line-height: 25rem;
          border-radius: 4rem;
          background: #ff8955;
          font-size: 13rem;
          font-weight: 500;
          letter-spacing: 0em;
          font-variation-settings: "opsz" auto;
          font-feature-settings: "kern" on;
          color: #ffffff;
          text-align: center;
          margin-right: 8rem;
        }

        .txt {
          width: 258rem;
          height: 23rem !important;
          margin-top: 2rem;
          font-family: Source Han Sans;
          font-size: 13rem;
          color: #303030;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: bold;
        }
      }

      // 右侧
      .right {
        color: #ff8955;
        cursor: pointer;
        font-size: 13rem;
      }
    }

    // 主题
    .theme {
      width: 100%;
      // padding: 12rem 5rem;
      border-radius: 8rem;
      box-sizing: border-box;

      // 商品
      .shop {
        margin-bottom: 5rem;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        box-sizing: border-box;
        width: 352rem;

        .shops {
          min-width: 170rem;
          width: fit-content;
          max-width: 340rem;
          background: linear-gradient(284deg, #fee5b6 0%, #fff4da 52%);
          margin-bottom: 7rem;
          border-radius: 8rem;
          margin-right: 6rem;
          padding: 10rem;
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          flex-direction: column;
          justify-content: space-between;
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

          .top {
            display: flex;
            align-items: flex-start;

            img {
              width: 32rem;
              height: 21rem;
              // width: 32rem; 未修改app
              // height: 22rem;
            }

            span {
              font-family: Source Han Sans;
              font-size: 16rem;
              font-weight: bold;
              line-height: normal;
              text-align: justify;
              letter-spacing: 0em;
              font-variation-settings: "opsz" auto;
              font-feature-settings: "kern" on;
              color: #5a3312;
              width: 100%;
            }
          }

          .tishis {
            // font-size: 14px;
            // width: 220px;
            color: #906a40;
          }

          .bottom {
            margin-top: 8rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            img {
              // width: 43rem; 未修改app
              // height: 18rem;
              width: 33rem;
              height: 12rem;
            }

            .txt {
              // position: absolute;
              // right: 16px;
              // bottom: 8px;
              color: #f7761a;

              .qian {
                font-family: HarmonyOS Sans SC;
                font-weight: 700;
                // font-size: 12rem; 未修改app
                font-size: 10rem;
                font-variation-settings: "opsz" auto;
              }

              .jiage {
                font-family: HarmonyOS Sans SC;
                font-weight: 700;
                // font-size: 20rem; 未修改app
                font-size: 16rem;
                font-variation-settings: "opsz" auto;
              }
            }
          }
        }

        .okshops {
          box-shadow: 0rem 0rem 1rem 1.6rem #e7921b;
        }
      }

      .shopone {
        background: linear-gradient(284deg, #fee5b6 0%, #fff4da 52%);
        margin-bottom: 10rem;
        border-radius: 8rem;
        padding: 15rem;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        flex-direction: column;
        justify-content: space-between;
        cursor: default;
        overflow: hidden;
        white-space: nowrap;

        .top {
          display: flex;
          align-items: flex-start;

          img {
            width: 34rem;
            height: 24rem;
          }

          span {
            font-family: Source Han Sans;
            font-size: 15rem;
            font-weight: bold;
            line-height: normal;
            text-align: justify;
            letter-spacing: 0em;
            font-variation-settings: "opsz" auto;
            font-feature-settings: "kern" on;
            color: #5a3312;
            width: 100%;
          }
        }

        .bottom {
          margin-top: 54rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          img {
            width: 78rem;
            height: 32rem;
          }

          .txt {
            // position: absolute;
            // right: 16px;
            // bottom: 8px;
            color: #f7761a;

            .qian {
              font-family: HarmonyOS Sans SC;
              font-weight: 700;
              font-size: 20rem;
              font-variation-settings: "opsz" auto;
            }

            .jiage {
              font-family: HarmonyOS Sans SC;
              font-weight: 700;
              font-size: 32rem;
              font-variation-settings: "opsz" auto;
            }
          }
        }
      }



      // 提示
      .csstips {
        padding:6rem 12rem;
        background-color: #f7f7f7;
        border-radius: 10rem;
        cursor: default;
        font-size: 8rem !important;
        ::v-deep span{
          //  font-size: 14rem !important;

        }
        .head {
          display: flex;
          align-items: center;

          img {
            width: 16rem;
            height: 16rem;
            margin-right: 6rem;
          }

          span {
            font-size: 14rem !important;
            font-weight: bold;
            color: #222222;
          }
        }
        

      }


      // 支付方式
      .pay {
        // display: flex;
        padding-top: 10rem;

        .left {
          font-size: 14rem;
          font-family: Source Han Sans;
          font-weight: 600;
          color: #000000;
          margin-bottom: 8rem;
        }

        .right {
          .btn {
            // width: 167rem;

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .paybtn {
              cursor: pointer;
              width: 167rem;
              height: 48rem;
              border-radius: 4rem;
              opacity: 1;
              background: #f7f7f7;
              margin-bottom: 13rem;
              // margin-right: 13rem;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;

              img {
                width: 28rem;
                height: 28rem;
                margin-right: 10rem;
              }

              span {
                font-family: Source Han Sans;
                font-size: 13rem;
                font-weight: bold;
                line-height: normal;
                letter-spacing: 0em;
                font-variation-settings: "opsz" auto;
                font-feature-settings: "kern" on;
                color: #191919;
              }

              .okzhong {
                position: absolute;
                right: -1rem;
                bottom: -1rem;
                width: 24rem;
                height: 24rem;
                margin-right: 0;
              }
            }

            .paybtnok {
              box-shadow: 0rem 0rem 0rem 2rem #3367ff;
            }
          }

          .prompt {
            font-size: 12rem;
            font-weight: normal;
            text-align: justify;
            letter-spacing: 0em;
            color: #878787;
          }
        }
      }

      .prompt {
        font-size: 12rem;
        font-weight: normal;
        line-height: 20px;
        text-align: justify;
        letter-spacing: 0em;
        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #878787;
        // margin: 16px 0 0 110px;
        // margin-left: 104px;
      }
    }
  }

  //底部
  .cssBottom {
    position: fixed;
    left: 0rem;
    bottom: 0rem;
    width: 100%;
    height: 64rem;
    opacity: 1;
    background: #ffffff;
    z-index: 9;
    box-shadow: inset 0px 1rem 0px 0px #f3f3f3;
    box-sizing: border-box;

    //底部内容
    .content {
      margin: 0 auto;
      padding: 0 14rem;
      box-sizing: border-box;
      width: 375rem;
      height: 100%;
      font-size: 16rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        // opacity: 0;
        background-color: #e3edff;
        border-radius: 375rem;
        padding: 10rem 33rem;

        .oneimg {
          position: fixed;
          opacity: 0;
        }

        .onespan {
          position: fixed;
          opacity: 0;
        }

        .twoimg {
          width: 24rem;
          height: 24rem;
          padding-right: 8rem;
        }

        .twospan {
          font-size: 14rem;
          font-weight: bold;
          color: #2a77e9;
        }
      }

      .right {
        display: flex;

        .jine {
          position: fixed;
          top: -100rem;
          line-height: 52rem;
          opacity: 0;

          .one {
            overflow: hidden;
          }

          .two {
            font-family: Source Han Sans;
            font-weight: 700;
            font-size: 16rem;
            font-variation-settings: "opsz" auto;
            color: #fff;
          }

          .san {
            font-weight: 700;
            font-size: 16rem;
            color: #fff;
          }

          .si {
            opacity: 0;
          }
        }

        .btn {
          padding: 11rem 50rem;
          background: #3369ff;
          text-align: center;
          color: #fff;
          cursor: pointer;
          font-weight: 700;
          font-size: 16rem;
          color: #fff;
          border-radius: 375rem;
          //  {
          //   position: absolute;
          //   left: 120rem;
          // }
        }
      }
    }
  }



  // 客服
  .csskefu {
    position: fixed;
    right: 0;
    bottom: 80rem;
    font-family: Source Han Sans;
    font-size: 10rem;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
    font-variation-settings: "opsz" auto;
    font-feature-settings: "kern" on;
    color: #ffffff;

    .item {
      width: 58rem;
      height: 25rem;
      opacity: 1;
      box-shadow: 0rem 2rem 4rem 0rem rgba(132, 87, 249, 0.27);
      border-radius: 8rem 0 0 8rem;
      margin-bottom: 7rem;
      line-height: 25rem;
      text-align: center;
      cursor: pointer;
    }

    .zaixian {
      background: #f53d4f;
    }

    .qq {
      background: #3369ff;
    }

    .dianbao {
      background: linear-gradient(270deg, #3d68f5 0%, #e940ff 100%);
    }
  }

  // 提示
  .tishi {
    cursor: default;
    position: fixed;
    left: 50%;
    top: -10vh;
    transform: translate(-50%, -50%);
    font-family: Source Han Sans;
    font-size: 18rem;
    z-index: 999;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 15rem;
    border-radius: 5rem;
    transition: all 0.3s ease-in-out;
    width: 200rem;
    text-align: center;
  }

  .tishis {
    // top: 160rem;
    top: 50vh;
  }

  //遮罩层
  .zhe {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    text-align: center;

    img {
      position: absolute;
      width: 100rem;
      top: 50vh;
      left: 50vw;
      margin-top: -50rem;
      margin-left: -50rem;
      transform-origin: 50rem 50rem;
      animation: slidein 2s linear infinite;
    }

    @keyframes slidein {
      0% {
        transform: rotate(0deg);
      }

      50% {
        transform: rotate(180deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>